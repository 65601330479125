import React, { useState } from "react";
import {
  Modal,
  Form,
  notification,
  Upload,
  Row,
  Col,
  Button,
  Input,
  Space,
  Select,
} from "antd";
import Joi from "joi";
import {
  PlusOutlined,
  MinusCircleOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import http from "../../helpers/http";
import config from "../../config/config";
import _ from "lodash";
import helper from "../../helpers/helper";
import slugify from "react-slugify";

const Create = ({
  isVisible,
  handleClose,
  moduleName,
  image_url,
  base_url,
  categories,
}) => {
  const [form] = Form.useForm();
  const [loading, setloading] = useState(false);
  const [formError, setFormError] = useState({});

  const sizeLimit = 50;

  const allowedExtensions = config.allowed_extensions;
  const joiOptions = config.joiOptions;

  const { TextArea } = Input;
  const { Option } = Select;

  const validate = async (value) => {
    let schemaObj = {
      name: Joi.string().required(),
      address: Joi.string().required(),
      city: Joi.string().required(),
      state: Joi.string().required(),
      zip: Joi.number().required(),
      latitude: Joi.number().required(),
      longitude: Joi.number().required(),
      phone: Joi.number().required(),
      mapLink: Joi.string().required().label("link"),
    };

    const schema = Joi.object(schemaObj).options({ allowUnknown: true });

    const { error } = schema.validate(value, joiOptions);

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }
    // validating unique slug
    // if (value.slug) {
    //   const result = await helper.validateSlug(value.slug, "Product");

    //   if (result == false) {
    //     errors["slug"] = `This slug is already used.`;
    //   }
    // }
    // helper.validateSize("image", value.image, sizeLimit, errors);
    // helper.validateExt("image", value.image, allowedExtensions, errors);

    return errors ? errors : null;
  };

  const handleSubmit = async (value) => {
    console.log({ value: value.products });
    let errors = await validate(value);
    setFormError(errors);
    console.log(errors);
    if (Object.keys(errors).length) {
      return;
    }

    setloading(true);
    let formData = new FormData();

    for (const [key, data] of Object.entries(_.omit(value, ["image"]))) {
      formData.append(key, data || "");
    }
    if (value.image) {
      formData.append("image", value.image.file);
    }

    const { data } = await http.post(base_url, formData);

    if (data) {
      notification["success"]({
        message: `${moduleName} Added Successfully`,
      });
      setloading(false);

      form.resetFields();

      handleClose();
    }
  };

  return (
    <Modal
      title={`Add ${moduleName}`}
      open={isVisible}
      onCancel={handleClose}
      okText="Create"
      cancelText="Cancel"
      confirmLoading={loading}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            handleSubmit(values);
          })
          .catch((info) => {});
      }}
      width={700}
    >
      <Form layout="vertical" form={form}>
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Form.Item
              required
              label="Store Name"
              name="name"
              {...(formError.name && {
                help: formError.name,
                validateStatus: "error",
              })}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              required
              label="Google Map Link"
              name="mapLink"
              {...(formError.mapLink && {
                help: formError.mapLink,
                validateStatus: "error",
              })}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} md={24}>
            <Form.Item
              required
              label="Address"
              name="address"
              {...(formError.address && {
                help: formError.address,
                validateStatus: "error",
              })}
            >
              <TextArea rows={4} />
            </Form.Item>
          </Col>

          <Col xs={24} md={8}>
            <Form.Item
              required
              label="State"
              name="state"
              {...(formError.state && {
                help: formError.state,
                validateStatus: "error",
              })}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              required
              label="City"
              name="city"
              {...(formError.city && {
                help: formError.city,
                validateStatus: "error",
              })}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              required
              label="Zip Code"
              name="zip"
              {...(formError.zip && {
                help: formError.zip,
                validateStatus: "error",
              })}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              required
              label="Latitude"
              name="latitude"
              {...(formError.latitude && {
                help: formError.latitude,
                validateStatus: "error",
              })}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              required
              label="Longitude"
              name="longitude"
              {...(formError.longitude && {
                help: formError.longitude,
                validateStatus: "error",
              })}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              required
              label="Phone No"
              name="phone"
              {...(formError.phone && {
                help: formError.phone,
                validateStatus: "error",
              })}
            >
              <Input />
            </Form.Item>
          </Col>
         
         
        </Row>
      </Form>
    </Modal>
  );
};

export default Create;
