import React, { useState } from "react";
import "./App.less";
import { Layout, Menu, Modal, Drawer, Button } from "antd";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";
import {
  ExclamationCircleOutlined,
  MenuOutlined,
  HolderOutlined,
} from "@ant-design/icons";

import ProductCategories from "./pages/ProductCategories/Index";
import Products from "./pages/Products/Index";
import Stores from "./pages/Stores/Index";

import ContactLeads from "./pages/leads/Contact";

import NotFound from "./pages/NotFound/Index";

import Logout from "./components/Logout";

//new code

import Recipes from "./pages/Recipes/Index";
import Media from "./pages/Media/Index";
import Commercials from "./pages/Commercials/Index";

function MainLayout(props) {
  const { Header, Content, Footer, Sider } = Layout;
  const { SubMenu } = Menu;
  const { confirm } = Modal;
  const [collapsed, setCollapsed] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedKeys, setselectedKeys] = useState(0);
  const onClose = () => {
    setOpen(false);
  };
  const onCollapse = (collapsed) => {
    setCollapsed(!collapsed);
  };

  const showLogoutConfirm = (id) => {
    confirm({
      title: `Are you sure you want to logout?`,
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",

      onOk() {
        // return handleDelete(id);
        props.history.push("/logout");
      },
      onCancel() {},
    });
  };
  const menuitems = [
    // { label: "item 1", key: "item-1" }, // remember to pass the key prop

    {
      label: "Products",
      key: "products-submenu",
      icon: <HolderOutlined />,

      children: [
        {
          label: <Link to="/products/categories">Categories</Link>,
          key: "categories",
        },
        {
          label: <Link to="/products">Products</Link>,
          key: "products",
        },
      ],
    },

    {
      icon: <HolderOutlined />,
      label: <Link to="/stores">Stores</Link>,
      key: "stores",
    },
    // {
    //   icon: <HolderOutlined />,
    //   label: <Link to="/media">Media</Link>,
    //   key: "media",
    // },
    // {
    //   icon: <HolderOutlined />,
    //   label: <Link to="/recipes">Recipes</Link>,
    //   key: "recipes",
    // },
    {
      icon: <HolderOutlined />,
      label: <Link to="/commercials">Commercials</Link>,
      key: "commercials",
    },
    {
      label: "Leads",
      key: "leads-submenu",
      icon: <HolderOutlined />,

      children: [
        {
          label: <Link to="/leads/contact">Contact</Link>,
          key: "contact",
        },
      ],
    },
  ];
  const topMenuItems = [
    {
      label: (
        <Link
          to=""
          onClick={() => {
            showLogoutConfirm();
          }}
        >
          Logout
        </Link>
      ),
      key: "Logout",
    }, // remember to pass the key prop
    // { label: "item 2", key: "item-2" }, // which is required
  ];
  const MenuComponent = (
    <Menu theme="light" mode="inline" items={menuitems}>
      {/* <SubMenu key="Homepage" icon={<HolderOutlined />} title="Homepage">
      <Menu.Item key="1">
          <Link to="/homepage/partners">Partners</Link>
        </Menu.Item>
        <Menu.Item key="10">
          <Link to="/homepage/galleries">Galleries</Link>
        </Menu.Item>
       
        <Menu.Item key="2">
          <Link to="/homepage/features">Features</Link>
        </Menu.Item>
        
        <Menu.Item key="3">
          <Link to="/homepage/brownies">Brownies</Link>
        </Menu.Item>
        <Menu.Item key="11">
          <Link to="/homepage/living-features">Living Features</Link>
        </Menu.Item>
       
        <Menu.Item key="4">
          <Link to="/homepage/content">Content</Link>
        </Menu.Item>
      </SubMenu> */}
      {/* <SubMenu key="AboutUs" icon={<HolderOutlined />} title="About Us">
        <Menu.Item key="5">
          <Link to="/about-us/teams">Teams</Link>
        </Menu.Item>
      </SubMenu> */}
      {/* <SubMenu key="Amenity" icon={<HolderOutlined />} title="Amenities">
        <Menu.Item key="6">
          <Link to="/amenity/categories">Category</Link>
          
        </Menu.Item>
        <Menu.Item key="7">
          <Link to="/amenities">Amenities</Link>
          
        </Menu.Item>
        <Menu.Item key="8">
          <Link to="/amenity/galleries">Gallery</Link>
          
        </Menu.Item>
        <Menu.Item key="9">
        <Link to="/amenity/content">Content</Link>
          
        </Menu.Item>
      </SubMenu> */}
    </Menu>
  );
  return (
    <Router>
      <Layout style={{ minHeight: "100vh" }}>
        <Sider
          // theme="dark"
          // collapsible
          // collapsed={collapsed}
          // onCollapse={() => {
          //   onCollapse(collapsed);
          // }}
          theme="light"
          breakpoint="lg"
          collapsedWidth="0"
          onBreakpoint={(broken) => {
            console.log(broken);
          }}
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type);
          }}
          trigger={null}
        >
          {/* <div className="logo">
            <span>Raymond</span>
            <img src={Logo} className="img-fluid" />
          </div> */}
          {MenuComponent}
        </Sider>
        <Layout className="site-layout">
          <Header className="site-layout-background" style={{ padding: 0 }}>
            <Button
              className="menu"
              type="primary"
              icon={<MenuOutlined />}
              onClick={() => setOpen(true)}
            />
            <Menu theme="dark" mode="horizontal" items={topMenuItems} />
            {/* <Menu.Item key="1" >
                <Link
                to=""
                  onClick={() => {
                    showLogoutConfirm();
                  }}
                >
                  Logout
                </Link>
              </Menu.Item> */}
          </Header>
          <Content style={{ margin: "0 16px" }}>
            <Switch>
              <Route exact path="/" component={Products} />
              {/* <Route exact path="/recipes" component={Recipes} /> */}

              <Route
                exact
                path="/products/categories"
                component={ProductCategories}
              />
              <Route exact path="/products" component={Products} />
              <Route exact path="/stores" component={Stores} />
              {/* <Route exact path="/media" component={Media} />
              <Route exact path="/recipes" component={Recipes} /> */}
              <Route exact path="/commercials" component={Commercials} />

              <Route exact path="/leads/contact" component={ContactLeads} />

              <Route exact path="/logout" component={Logout} />
              <Route path="*" component={NotFound} />
            </Switch>
          </Content>
          <Footer style={{ textAlign: "center" }}>
            Design & Developed by Togglehead
          </Footer>
        </Layout>

        <Drawer title="Menu" placement="left" onClose={onClose} open={open}>
          {MenuComponent}
        </Drawer>
      </Layout>
    </Router>
  );
}

export default MainLayout;
