let config = {
  api_url: process.env.REACT_APP_API_URL,
  jwt_store_key: "garvi_jwt_secret_storage",
  sizeLimit: 5,
  allowed_extensions: ["image/jpeg", "image/png", "image/svg+xml", "image/svg"],
  azure_object_url: process.env.REACT_APP_AZURE_OBJECT_URL,
  joiOptions: {
    abortEarly: false,
    allowUnknown: true,
    errors: {
      wrap: {
        label: "",
      },
    },
  },
};

export default config;
