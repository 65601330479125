import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  Row,
  Col,
  Card,
  Space,
  Button,
  Modal,
  notification,
  Switch,
  Image,
  Tag,
  List,
} from "antd";
import { Table } from "ant-table-extensions";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import Edit from "./Edit";
import Create from "./Create";
import Ordering from "./Ordering";
import BulkCreate from "./BulkCreate";

import http from "../../helpers/http";

import config from "../../config/config";
import { useMediaQuery } from "react-responsive";
import helper from "../../helpers/helper";
import bulkFormat from "../../garvi-bulk-format.zip";

function Index(props) {
  const { confirm } = Modal;
  const [loading, setloading] = useState(false);

  const [editOpen, setEditOpen] = useState(false);
  const [createOpen, setCreateOpen] = useState(false);
  const [bulkcreateOpen, setBulkCreateOpen] = useState(false);

  const [isOrderOpen, setisOrderOpen] = useState(false);

  const [data, setData] = useState({});
  //createKey and editKey is used to reset modal data on close
  const [createKey, setCreateKey] = useState(1);
  const [editKey, seteditKey] = useState(1);
  const [bulkcreateKey, setBulkCreateKey] = useState(Math.random() * 10);
  const [bulktype, setBulkType] = useState();
  const [datas, setDatas] = useState([]);
  const isMobile = useMediaQuery({ query: "(max-width: 576px)" });
  const [btnSize, setBtnSize] = useState("middle");
  const [switchSize, setSwitchSize] = useState("default");
  const [categories, setCategories] = useState();

  const moduleName = "Product";
  const moduleNamePural = "Products";

  const base_url = config.api_url + "admin/products";
  const image_url = config.api_url + "uploads/product/";
  const azure_object_url = config.azure_object_url;

  const [modal, contextHolder] = Modal.useModal();

  const handleEdit = async (id) => {
    const { data } = await http.get(`${base_url}/${id}`);

    if (data) {
      seteditKey((state) => {
        return state + 1;
      });
      setData(data);
      setEditOpen(true);
    }
  };
  const handleCreate = () => {
    setCreateOpen(true);
  };

  const handleEditClose = () => {
    seteditKey((state) => {
      return state + 1;
    });
    setData({});
    setEditOpen(false);
    fetchDatas();
  };

  const handleCreateClose = () => {
    setCreateKey(createKey + 1);
    setCreateOpen(false);
    fetchDatas();
  };

  const handleOrderClose = () => {
    setisOrderOpen(!isOrderOpen);
    fetchDatas();
  };

  const handleBulkCreateClose = () => {
    setBulkCreateKey((state) => {
      return state + Math.random() * 2;
    });

    setBulkCreateOpen(false);
    fetchDatas();
  };

  const handleBulkCreate = (type) => {
    setBulkCreateKey((state) => {
      return state + Math.random() * 2;
    });
    setBulkCreateOpen(true);
    setBulkType(type);
  };

  const handleStatus = async (value, id) => {
    const status = value ? 1 : 0;

    const result = await http.put(`${base_url}/status/${id}`, {
      status: status,
    });

    if (result) {
      notification["success"]({
        message: "Status Changed Successfully",
      });
    }

    fetchDatas();
  };

  const handleFeatured = async (value, id) => {
    const is_featured = value ? 1 : 0;

    const result = await http.put(`${base_url}/featured/${id}`, {
      is_featured: is_featured,
    });

    if (result) {
      notification["success"]({
        message: "Featured Status Changed Successfully",
      });
    }

    fetchDatas();
  };

  const handleDelete = async (id) => {
    const result = await http.delete(`${base_url}/${id}`);
    if (result) {
      notification["success"]({
        message: `${moduleName} Deleted Successfully`,
      });
    }

    fetchDatas();
  };

  const showDeleteConfirm = (id) => {
    confirm({
      title: `Are you sure delete this ${moduleName}?`,
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",

      onOk() {
        return handleDelete(id);
      },
      onCancel() {},
    });
  };

  const fetchDatas = async () => {
    setloading(true);
    const data = await http.get(base_url);

    if (data) {
      setDatas(data.data);
    }
    setloading(false);
  };

  const fetchCategory = async () => {
    const data = await http.get(config.api_url + "admin/products/categories");

    if (data) {
      setCategories(data.data);
    }
  };

  const handleBulkMessage = (title, content, type) => {
    if (type === "success") {
      modal.success({
        title: title,
        content: content.map((error) => {
          return <li>{error}</li>;
        }),
      });
    } else {
      modal.warning({
        title: title,
        content: (
          <List
            size="small"
            dataSource={content}
            renderItem={(item) => <List.Item>{item}</List.Item>}
          />
        ),
      });
    }
  };
  useEffect(() => {
    fetchDatas();
    fetchCategory();
  }, []);

  useEffect(() => {
    if (isMobile) {
      setBtnSize("small");
      setSwitchSize("small");
    }
  }, [isMobile]);

  const columns = [
    {
      title: "Image",

      key: "image",
      render: (text, record) => (
        <Image
          width={isMobile ? 80 : 100}
          src={`${azure_object_url}${record.image}`}
          fallback="https://via.placeholder.com/100"
        />
      ),
    },

    {
      title: "Category",
      render: (text, record) => {
        return record?.category?.title ? (
          record?.category?.title
        ) : (
          <Tag color="red">Category Not Available</Tag>
        );
      },
      key: "category",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      // expandable: true,
      // responsive: ["lg"],
    },
    {
      title: "Slug",
      dataIndex: "slug",
      key: "slug",
      // expandable: true,
      // responsive: ["lg"],
    },

    {
      title: "Status",
      key: "status",
      render: (text, record) => (
        <Space size="middle">
          <Switch
            size={switchSize}
            checkedChildren="On"
            unCheckedChildren="Off"
            onChange={(value) => {
              handleStatus(value, record._id);
            }}
            defaultChecked={record.status}
          />
        </Space>
      ),
    },
    {
      title: "Featured",
      key: "featured",
      render: (text, record) => (
        <Space size="middle">
          <Switch
            checkedChildren="Yes"
            unCheckedChildren="No"
            onChange={(value) => {
              handleFeatured(value, record._id);
            }}
            defaultChecked={record.is_featured}
          />
        </Space>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Button
            type="primary"
            onClick={() => {
              handleEdit(record._id);
            }}
            size={btnSize}
          >
            Edit
          </Button>
          <Button
            type="danger"
            onClick={() => {
              showDeleteConfirm(record._id);
            }}
            size={btnSize}
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  const fields: ITableExportFields = {
    title: "title",
    slug: "slug",
    description: "description",

    category: {
      header: "category",
      formatter: (_fieldValue, record) => {
        return record.category?.slug;
      },
    },

    image: {
      header: "image",
      formatter: (fieldValue, record) => {
        return `${config.azure_object_url}${fieldValue}`;
      },
    },
    weight: "weight",
    status: "status",
    is_featured: "is_featured",
  };

  return (
    <div>
      {contextHolder}
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Dashboard</Breadcrumb.Item>

        <Breadcrumb.Item>{moduleNamePural}</Breadcrumb.Item>
      </Breadcrumb>

      <Row>
        <Col span={24}>
          <div className="site-card-border-less-wrapper">
            <Card title={moduleNamePural} bordered={false}>
              <Row style={{ marginBottom: "20px" }}>
                <Col span={24}>
                  <Space>
                    <Button
                      type="primary"
                      onClick={handleCreate}
                      size={btnSize}
                    >
                      Add {moduleName}
                    </Button>
                    <Button
                      type="primary"
                      onClick={() => {
                        console.log("order clicked");
                        setisOrderOpen(true);
                      }}
                      size={btnSize}
                    >
                      View Ordering
                    </Button>
                    <Button
                      type="primary"
                      onClick={() => handleBulkCreate("create")}
                    >
                      Bulk Upload
                    </Button>
                    <Button
                      type="primary"
                      onClick={() => handleBulkCreate("edit")}
                    >
                      Bulk Edit
                    </Button>
                    <a href={bulkFormat} download>
                      <Button type="primary">Download Format</Button>
                    </a>
                  </Space>
                </Col>
              </Row>

              <Table
                loading={loading}
                size={switchSize}
                rowKey={(record) => record._id}
                columns={columns}
                dataSource={datas}
                expandable={{
                  expandedRowRender: (record) => (
                    <>
                      {columns
                        .filter((col) => col.expandable)
                        .map((col) => {
                          return (
                            <p>
                              <b>{col.title}: </b>{" "}
                              {col.isDate
                                ? helper.ISTDate(record[col.dataIndex])
                                : record[col.dataIndex] || "-"}
                            </p>
                          );
                        })}
                    </>
                  ),
                  showExpandColumn: isMobile,
                  // rowExpandable: (record) => record.name !== "Not Expandable",
                }}
                footer={() => `Total ${moduleNamePural} =  ${datas.length}`}
                exportableProps={{
                  showColumnPicker: true,
                  fields,
                  fileName: moduleNamePural,
                }}
                searchableProps={{
                  fuzzySearch: true,
                }}
              />

              <Edit
                key={"edit" + editKey}
                isVisible={editOpen}
                handleClose={handleEditClose}
                dbData={data}
                moduleName={moduleName}
                base_url={base_url}
                image_url={azure_object_url}
                categories={categories}
              />
              <Create
                key={"create" + createKey}
                isVisible={createOpen}
                handleClose={handleCreateClose}
                moduleName={moduleName}
                base_url={base_url}
                image_url={azure_object_url}
                categories={categories}
              />
              <Ordering
                // key={"order" + orderKey}

                isOpen={isOrderOpen}
                onClose={handleOrderClose}
                datas={datas}
                moduleName={moduleName}
                isMobile={isMobile}
                base_url={base_url}
                image_url={azure_object_url}
              />
              <BulkCreate
                key={"bulkcreate" + bulkcreateKey}
                isVisible={bulkcreateOpen}
                handleClose={handleBulkCreateClose}
                moduleName={moduleName}
                handleBulkMessage={handleBulkMessage}
                type={bulktype}
                base_url={base_url}
                image_url={azure_object_url}
              />
            </Card>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Index;
