import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  notification,
  Upload,
  Row,
  Col,
  Select,
  Button,
  Alert,
} from "antd";
import Joi from "joi";

import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import slugify from "react-slugify";

import http from "../../helpers/http";

import axios from "axios";
import _ from "lodash";

import helper from "../../helpers/helper";

const BulkCreate = ({
  isVisible,
  handleClose,
  moduleName,
  handleBulkMessage,
  type,
  image_url,
  base_url,
}) => {
  const [form] = Form.useForm();
  const [loading, setloading] = useState(false);
  const [formError, setFormError] = useState({});

  const sizeLimit = 1;

  const { TextArea } = Input;
  const { Option } = Select;

  //validation schema

  const schema = Joi.object({
    excel: Joi.required(),
  }).options({ allowUnknown: true });

  const validate = async (data) => {
    const { error } = schema.validate(data, {
      abortEarly: false,
      allowUnknown: true,
      errors: {
        wrap: {
          label: "",
        },
      },
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }
    if (data.excel) {
      if (data.excel.file.size > sizeLimit * 1024 * 1024) {
        errors["excel"] = `File needs to be under ${sizeLimit}MB`;
      }
      let allowedExt = [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel",
      ];
      if (!allowedExt.includes(data.excel.file.type)) {
        errors["excel"] = "File does not have a valid file extension.";
      }
    }

    return errors ? errors : null;
  };

  const handleSubmit = async (value) => {
    let errors = await validate(value);
    setFormError(errors);

    if (Object.keys(errors).length) {
      return;
    }

    setloading(true);
    let formData = new FormData();
    // for (const [key, data] of Object.entries(
    //   _.omit(value, ["image", "barcode"])
    // )) {
    //   formData.append(key, data || "");
    // }

    formData.append("excel", value.excel.file);

    try {
      let bulkUrl;
      if (type === "create") {
        bulkUrl = base_url + "/bulk/create";
      } else {
        bulkUrl = base_url + "/bulk/edit";
      }
      const response = await axios.post(bulkUrl, formData);

      const { data } = response;
      // console.log(data);
      if (data.status === 200) {
        // notification["success"]({
        //   message: data.message,
        //   duration: 0,
        // });
        setloading(false);
        handleClose();
        handleBulkMessage(data.message, [], "success");
        return;
      }

      if (data.status >= 400 || data.status <= 499) {
        let description = "";

        // data.data.forEach((error) => {
        //   description += "<li>" + error + "</li>";
        // });
        // notification["error"]({
        //   message: data.message,
        //   description: description,
        //   duration: 0,
        // });
        setloading(false);
        handleClose();
        handleBulkMessage(data.message, data.data, "error");
        return;
      }
    } catch (error) {
      notification["error"]({
        message: "Internal Server Error",
      });
      console.log("error", error.response);
      //   return false;
    }

    // const { data } = await axios.post(base_url, formData);

    // if (data) {
    //   notification["success"]({
    //     message: `${moduleName} Added Successfully`,
    //   });
    //   setloading(false);

    //   // form.resetFields();
    // }
  };

  useEffect(() => {
    // notification["error"]({
    //   message: "demo message",
    //   description: `hello%0Dworld`,
    //   duration: 0,
    // });
    // setCategories(allCategory);
  }, []);

  return (
    <Modal
      title={`Bulk ${type === "create" ? "Add" : "Edit"} ${moduleName}`}
      visible={isVisible}
      onCancel={handleClose}
      okText="Upload"
      cancelText="Cancel"
      confirmLoading={loading}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            handleSubmit(values);
          })
          .catch((info) => {});
      }}
      // width={1000}
    >
      <Form layout="vertical" form={form}>
        <Row>
          <Col span={12}>
            <Form.Item
              required
              label="Excel File: (.xslx)"
              name="excel"
              {...(formError.excel && {
                help: formError.excel,
                validateStatus: "error",
              })}
            >
              <Upload
                beforeUpload={(file) => {
                  return false;
                }}
                maxCount={1}
                listType="text"
                showUploadList={{
                  showPreviewIcon: false,
                  showRemoveIcon: false,
                }}
              >
                <Button icon={<UploadOutlined />}>Select File</Button>
                {/* <PlusOutlined /> */}
              </Upload>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default BulkCreate;
