import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  notification,
  Upload,
  Row,
  Col,
  Button,
  Input,
  Space,
  Select,
} from "antd";

import {
  PlusOutlined,
  MinusCircleOutlined,
  EditOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import Joi from "joi";
import _ from "lodash";
import http from "../../helpers/http";
import config from "../../config/config";
import helper from "../../helpers/helper";
import slugify from "react-slugify";

function Edit({
  isVisible,
  handleClose,
  dbData,
  moduleName,
  image_url,
  base_url,
  categories,
}) {
  const [form] = Form.useForm();
  const [loading, setloading] = useState(false);
  const [formError, setFormError] = useState({});

  const sizeLimit = 50;

  const allowedExtensions = config.allowed_extensions;
  const joiOptions = config.joiOptions;
  const { TextArea } = Input;
  const { Option } = Select;

  const validate = async (value) => {
    let schemaObj = {
      // document: Joi.required(),
      title: Joi.string().required(),
      slug: Joi.string().required(),
      weight: Joi.number().required(),
      category: Joi.string().required(),
    };

    const schema = Joi.object(schemaObj).options({ allowUnknown: true });

    const { error } = schema.validate(value, joiOptions);

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    if (value.slug && dbData.slug != value.slug) {
      const result = await helper.validateSlug(value.slug, "Product");

      if (result == false) {
        errors["slug"] = `This slug is already used.`;
      }
    }

    helper.validateSize("image", value.image, sizeLimit, errors);
    helper.validateExt("image", value.image, allowedExtensions, errors);

    return errors ? errors : null;
  };

  const handleSubmit = async (value) => {
    let errors = await validate(value);
    setFormError(errors);

    if (Object.keys(errors).length) {
      return;
    }

    setloading(true);
    let formData = new FormData();

    for (const [key, data] of Object.entries(_.omit(value, ["image"]))) {
      formData.append(key, data || "");
    }

    if (value.image) {
      formData.append("image", value.image.file);
    }

    const { data } = await http.put(`${base_url}/${dbData._id}`, formData);

    if (data) {
      notification["success"]({
        message: `${moduleName} Updated Successfully`,
      });
      setloading(false);
      form.resetFields();
      handleClose();
    }
  };

  useEffect(() => {
    form.setFieldsValue(_.omit(dbData, ["image"]));
  }, [dbData]);

  return (
    <Modal
      title={`Edit ${moduleName}`}
      open={isVisible}
      onCancel={handleClose}
      okText="Update"
      cancelText="Cancel"
      confirmLoading={loading}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            handleSubmit(values);
          })
          .catch((info) => {});
      }}
      width={1000}
    >
      <Form layout="vertical" form={form}>
        <Row gutter={16}>
          <Col xs={24} md={8}>
            <Form.Item
              required
              label="Category:"
              name="category"
              {...(formError.category && {
                help: formError.category,
                validateStatus: "error",
              })}
            >
              <Select
                placeholder="Select Category"
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {categories &&
                  categories.map((data) => {
                    return <Option value={data._id}>{`${data.title}`}</Option>;
                  })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              required
              label="Title"
              name="title"
              {...(formError.title && {
                help: formError.title,
                validateStatus: "error",
              })}
            >
              <Input
                onChange={(value) => {
                  form.setFieldsValue({ slug: slugify(value.target.value) });
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              required
              label="Slug:"
              name="slug"
              {...(formError.slug && {
                help: formError.slug,
                validateStatus: "error",
              })}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={24}>
            <Form.Item
              label="Description"
              name="description"
              {...(formError.description && {
                help: formError.description,
                validateStatus: "error",
              })}
            >
              <TextArea rows={4} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              required
              label="Image: (Anything X 302)"
              name="image"
              {...(formError.image && {
                help: formError.image,
                validateStatus: "error",
              })}
            >
              <Upload
                beforeUpload={(file) => {
                  return false;
                }}
                maxCount={1}
                listType="picture-card"
                showUploadList={{
                  showPreviewIcon: false,
                  showRemoveIcon: false,
                }}
                defaultFileList={[
                  {
                    thumbUrl: dbData.image
                      ? image_url + dbData.image
                      : "https://via.placeholder.com/100",
                  },
                ]}
              >
                <EditOutlined />
              </Upload>
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              required
              label="Weight (in grams):"
              name="weight"
              {...(formError.weight && {
                help: formError.weight,
                validateStatus: "error",
              })}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default Edit;
