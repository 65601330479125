import React, { useState } from "react";
import {
  Modal,
  Form,
  notification,
  Upload,
  Row,
  Col,
  Button,
  Input,
  DatePicker,
} from "antd";
import Joi from "joi";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import http from "../../helpers/http";
import config from "../../config/config";
import _ from "lodash";
import helper from "../../helpers/helper";

const Create = ({
  isVisible,
  handleClose,
  moduleName,
  image_url,
  base_url,
}) => {
  const [form] = Form.useForm();
  const [loading, setloading] = useState(false);
  const [formError, setFormError] = useState({});
  const [date, setDate] = useState();

  const sizeLimit = config.sizeLimit;

  const allowedExtensions = config.allowed_extensions;
  const joiOptions = config.joiOptions;

  const { TextArea } = Input;

  const validate = (value) => {
    let schemaObj = {
      thumbnail: Joi.required(),
      video_url: Joi.string().uri().required().messages({
        "string.uri": "video url must be a valid URL",
        "string.empty": "video url is required",
        "any.required": "video url is required",
      }),
    };

    const schema = Joi.object(schemaObj).options({ allowUnknown: true });

    const { error } = schema.validate(value, joiOptions);

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    helper.validateSize("thumbnail", value.thumbnail, sizeLimit, errors);
    helper.validateExt("thumbnail", value.thumbnail, allowedExtensions, errors);

    return errors ? errors : null;
  };

  const handleSubmit = async (value) => {
    let errors = validate(value);
    setFormError(errors);
    if (Object.keys(errors).length) {
      return;
    }

    setloading(true);
    let formData = new FormData();

    for (const [key, data] of Object.entries(_.omit(value, []))) {
      formData.append(key, data || "");
    }
    if (value.thumbnail) {
      formData.append("thumbnail", value.thumbnail.file);
    }

    const { data } = await http.post(base_url, formData);

    if (data) {
      notification["success"]({
        message: `${moduleName} Added Successfully`,
      });
      setloading(false);

      form.resetFields();

      handleClose();
    }
  };

  return (
    <Modal
      title={`Add ${moduleName}`}
      open={isVisible}
      onCancel={handleClose}
      okText="Create"
      cancelText="Cancel"
      confirmLoading={loading}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            values.date = date;

            handleSubmit(values);
          })
          .catch((info) => {});
      }}

      // width={700}
    >
      <Form layout="vertical" form={form}>
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Form.Item
              required
              label="Thumbnail: (Anything X 302)"
              name="thumbnail"
              {...(formError.thumbnail && {
                help: formError.thumbnail,
                validateStatus: "error",
              })}
            >
              <Upload
                beforeUpload={(file) => {
                  return false;
                }}
                maxCount={1}
                listType="picture-card"
                showUploadList={{
                  showPreviewIcon: false,
                  showRemoveIcon: false,
                }}
              >
                <PlusOutlined />
              </Upload>
            </Form.Item>
          </Col>
          <Col xs={24} md={24}>
            <Form.Item
              required
              label="Video URL"
              name="video_url"
              {...(formError.video_url && {
                help: formError.video_url,
                validateStatus: "error",
              })}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default Create;
